* {
  font-family: 'Montserrat', -apple-system, system-ui, 'Raleway', 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif;
  /*font-family: 'Muli', sans-serif;*/
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: border-box;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 300;
}

h3 {
  font-weight: 300;
}

h4 {
  font-weight: 300;
}

p {
  line-height: 21px;
  font-size: 0.9em;
}

div {
  color:#212121
}

button {
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
}
